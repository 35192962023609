<template>
  <div>
    <div class="page-title">
      <h3>90 Days Completed</h3>
      <div class="page-breadcrumb">
        <ol class="breadcrumb">
          <li>
            <router-link
              :to="{name: 'home'}"
            >
              Home
            </router-link>
          </li>
          <li class="active">
            90 Days Completed
          </li>
        </ol>
      </div>
    </div>
    <div id="main-wrapper">
      <div
        v-if="jobType === 'Owner' || jobType === 'Manager' || jobType === 'Supervisor'"
        class="panel panel-white"
      >
        <div class="panel-heading clearfix">
          <h2 class="panel-title text-center">
            Assign Forms
          </h2>
        </div>
        <div class="panel-body">
          <div class="row">
            <div
              v-if="jobType === 'Owner'"
              class="form-group col-md-3"
            >
              <label class="has-float-label">
                <VueMultiselect
                  v-model="activeBranchId"
                  :allow-empty="false"
                  :class="{ 'placeholder-shown': (!activeBranchId || activeBranchId === '') }"
                  :options="getEnabledBranches"
                  :placeholder="null"
                  :show-labels="false"
                  label="name"
                  track-by="id"
                />
                <span class="float-label">{{ $Amplify.I18n.get('Branch') }}</span>
              </label>
            </div>
            <div class="form-group col-md-3">
              <label class="has-float-label">
                <VueMultiselect
                  v-model="selectedUser"
                  :allow-empty="false"
                  :class="{ 'placeholder-shown': (!selectedUser || selectedUser === '') }"
                  :custom-label="getUserName"
                  :options="_getUsers"
                  :placeholder="null"
                  :show-labels="false"
                  track-by="id"
                />
                <span class="float-label">{{ $Amplify.I18n.get('User') }}</span>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <label class="has-float-label">
                <input
                  v-model.number="assignCount"
                  name="assignCount"
                  placeholder=" "
                  type="number"
                >
                <span class="float-label">Number of Forms <i
                  aria-hidden="true"
                  class="fas fa-asterisk text-danger"
                  style="font-size: 7px; vertical-align: text-top;"
                /></span>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-1">
              <input
                id="assignFormsButton"
                class="btn btn-default"
                type="button"
                value="Assign Forms"
                @click.stop.prevent="assignForms"
              >
            </div>
          </div>
        </div>
      </div>
      <div class="panel panel-white">
        <div class="panel-heading clearfix">
          <h2 class="panel-title text-center">
            90 Days Completed Forms
          </h2>
        </div>
        <div class="panel-body">
          <div class="table-responsive">
            <table
              id="ninetyDaysFormsList"
              class="table table-striped table-bordered"
              style="width:100%"
            >
              <thead>
                <tr>
                  <th>Submission Date</th>
                  <th>Submission Time</th>
                  <th>Ninety Days Date</th>
                  <th>Bank Verification Date</th>
                  <th>Previously Applied Date</th>
                  <th>Resident City</th>
                  <th>Owner</th>
                  <th>Supervisor</th>
                  <th>Branch Manager</th>
                  <th>Status</th>
                  <th>Reference Number</th>
                  <th>Remarks</th>
                  <th>Offer Type</th>
                  <th>Product</th>
                  <th>Title</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Father Name</th>
                  <th>Mother Name</th>
                  <th>Date Of Birth</th>
                  <th>Age</th>
                  <th>Gender</th>
                  <th>Pancard</th>
                  <th>Email</th>
                  <th>Mobile Number</th>
                  <th>Alternate Number</th>
                  <th>Resident Address Line 1</th>
                  <th>Resident Address Line 2</th>
                  <th>Resident Landmark</th>
                  <th>Resident Station</th>
                  <th>Resident Pincode</th>
                  <th>Employment Status</th>
                  <th>Net Monthly Income</th>
                  <th>Years Employed</th>
                  <th>Company</th>
                  <th>Company Listed?</th>
                  <th>Company Address Line 1</th>
                  <th>Company Address Line 2</th>
                  <th>Company Landmark</th>
                  <th>Company Station</th>
                  <th>Company City</th>
                  <th>Company Pincode</th>
                  <th>Existing CC Bank 1</th>
                  <th>Existing CC Using From 1</th>
                  <th>Existing CC Total Limit 1</th>
                  <th>Existing CC Available Limit 1</th>
                  <th>Existing CC Bank 2</th>
                  <th>Existing CC Using From 2</th>
                  <th>Existing CC Total Limit 2</th>
                  <th>Existing CC Available Limit 2</th>
                  <th>Last Updated By</th>
                  <th>Updated On</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <AIPFormModal
        :form-details="formDetails"
        :job-type="jobType"
        :report-only="jobType !== 'BackOffice'"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {API, graphqlOperation} from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import VueMultiselect from "vue-multiselect";
import AIPFormModal from "../../components/AIPFormModal";

export default {
  name: "NinetyDays",
  components: {
    VueMultiselect,
    AIPFormModal
  },
  data() {
    return {
      forms: [],
      formsTable: null,
      formDetails: {},
      jobType: '',
      activeBranchId: null,
      selectedUser: null,
      assignCount: null,
    };
  },
  beforeRouteLeave(to, from, next) {
    let formModal = $("#formDetailsModal");
    if ((formModal.data('bs.modal') || {}).isShown) {
      formModal.modal('hide');
      document.getElementById('ninetyDaysFormsList').scrollIntoView({behavior: 'instant'});
      next(false);
    } else {
      next();
    }
  },
  computed: {
    ...mapGetters([
      "getLoggedInUser",
      "getBranches",
      "getStatuses",
      "getProducts",
      "getUsers",
      "getTitles"
    ]),
    ...mapState([
      'loggedInUser'
    ]),
    getEnabledBranches: function () {
      return _.sortBy(_.filter(this.getBranches, (branch) => {
        return branch.isEnabled;
      }), 'name');
    },
    _getUsers: function () {
      if (this.jobType === 'Owner') {
        return _.sortBy(_.filter(this.getTitleUsers('BranchManager'), (user) => {
          return _.includes(user.branchId, this.activeBranchId.number);
        }), 'username');
      } else if (this.jobType === 'Manager') {
        let supervisors = _.filter(this.getTitleUsers('Supervisor'), (user) => {
          return user.supervisor === this.getLoggedInUser.id;
        });
        let supervisorIds = _.map(supervisors, (supervisor) => {
          return supervisor.id;
        });
        let users = _.filter(this.getTitleUsers('User'), (user) => {
          return _.includes(supervisorIds, user.supervisor);
        });
        return _.sortBy(_.concat(supervisors, users), 'username');
      } else if (this.jobType === 'Supervisor') {
        return _.sortBy(_.filter(this.getTitleUsers('User'), (user) => {
          return user.supervisor === this.getLoggedInUser.id;
        }), 'username');
      } else {
        return [];
      }
    }
  },
  watch: {
    loggedInUser(newValue) {
      if (newValue && newValue !== '') {
        if (newValue.jobTitle && newValue.jobTitle !== '') {
          let jobTitle = _.find(this.getTitles, (title) => {
            return title.id === newValue.jobTitle;
          });
          if (jobTitle) {
            this.jobType = jobTitle.access;
          }
          this.activeBranchId = _.filter(this.getBranches, (branch) => {
            return branch.number === newValue.branchId[0];
          })[0];
          if (this.formsTable && jobTitle === 'Owner') {
            this.formsTable.button().add(2, {
              extend: 'excelHtml5',
              autoFilter: true,
              text: '<i class="far fa-file-excel"></i>',
              titleAttr: 'Excel'
            });
          }
        }
      }
    },
    forms() {
      this.updateFormsTableData();
    },
    activeBranchId() {
      this.fetchForms();
      this.selectedUser = this._getUsers[0];
    }
  },
  mounted() {
    $('#sidebar').removeClass('visible');
    $('.page-inner').removeClass('sidebar-visible');

    if (this.getLoggedInUser && this.getLoggedInUser !== '') {
      if (this.getLoggedInUser.jobTitle && this.getLoggedInUser.jobTitle !== '') {
        let jobTitle = _.find(this.getTitles, (title) => {
          return title.id === this.getLoggedInUser.jobTitle;
        });
        if (jobTitle) {
          this.jobType = jobTitle.access;
        }
        this.activeBranchId = _.filter(this.getBranches, (branch) => {
          return branch.number === this.loggedInUser.branchId[0];
        })[0];
      }
      this.createFormsTable();
    }
  },
  methods: {
    async fetchForms() {
      this.forms = [];
      if (this.jobType === 'BackOffice') {
        this.fetchFormsByStatus("2d986013-050e-4e8b-8b40-1b959be382c4", null, null);  //NOTE: Hardcoded ids = Bad idea
      } else {
        this.fetchFormsByStatusOwner("d73d381f-cf14-4388-9ec3-784a763c5431", this.getLoggedInUser.username, null, null); //NOTE: Hardcoded ids = Bad idea
      }
    },
    async fetchFormsByStatusOwner(status, owner, filter = null, nextPage = null) {
      const {
        data: {
          formsByStatusOwner: {items, nextToken}
        }
      } = await API.graphql(graphqlOperation(queries.formsByStatusOwner, {
        status: status,
        owner: {
          eq: owner
        },
        limit: 999,
        filter: filter,
        nextToken: nextPage
      }));
      this.forms = _.concat(this.forms, items);
      nextPage = nextToken;
      if (nextPage) {
        this.fetchFormsByStatusOwner(status, owner, filter, nextPage);
      }
    },
    async fetchFormsByStatus(status, filter = null, nextPage = null) {
      const {
        data: {
          formsByStatus: {items, nextToken}
        }
      } = await API.graphql(graphqlOperation(queries.formsByStatus, {
        status: status,
        limit: 999,
        filter: filter,
        nextToken: nextPage
      }));
      this.forms = _.concat(this.forms, items);
      nextPage = nextToken;
      if (nextPage) {
        this.fetchFormsByStatus(status, filter, nextPage);
      }
    },
    createFormsTable: function () {
      let buttons = [
        {
          extend: 'pageLength',
          text: '<i class="fas fa-filter"></i>',
          titleAttr: 'Number of Rows'
        },
        {
          extend: 'colvis',
          text: '<i class="fas fa-eye"></i>',
          titleAttr: 'Column Visibility',
          columns: ':not(.noVis)'
        }
      ];

      const currentUserJobTitle = _.result(_.find(this.getTitles, (title) => {
        return title.id === this.getLoggedInUser.jobTitle
      }), 'access');
      if (currentUserJobTitle === 'Owner') {
        buttons.push({
          extend: 'excelHtml5',
          autoFilter: true,
          text: '<i class="far fa-file-excel"></i>',
          titleAttr: 'Excel'
        });
      }
      this.formsTable = $('#ninetyDaysFormsList').DataTable({
        processing: true,
        dom: 'Bfrtip',
        lengthMenu: [
          [10, 25, 50, 100, -1],
          ['10 rows', '25 rows', '50 rows', '100 rows', 'Show all']
        ],
        data: this.forms,
        rowId: 'id',
        columns: [
          {
            data: 'createdAt'
          },
          {
            data: 'createdAt'
          },
          {
            data: 'dateNinetyDays',
            defaultContent: ""
          },
          {
            data: 'bankVerificationDate',
            defaultContent: ""
          },
          {
            data: 'previouslyAppliedDate',
            defaultContent: ""
          },
          {
            data: 'city',
            defaultContent: ""
          },
          {
            data: 'owner',
            defaultContent: ""
          },
          {
            data: 'supervisor',
            defaultContent: ""
          },
          {
            data: 'branchManager',
            defaultContent: ""
          },
          {
            data: 'status',
            defaultContent: ""
          },
          {
            data: 'referenceNumber',
            defaultContent: ""
          },
          {
            data: 'remarks',
            defaultContent: ""
          },
          {
            data: 'offerType',
            defaultContent: ""
          },
          {
            data: 'product',
            defaultContent: ""
          },
          {
            data: 'title',
            defaultContent: ""
          },
          {
            data: 'firstName'
          },
          {
            data: 'lastName'
          },
          {
            data: 'fatherName',
            defaultContent: ""
          },
          {
            data: 'motherName',
            defaultContent: ""
          },
          {
            data: 'dateOfBirth',
            defaultContent: ""
          },
          {
            data: 'age',
            defaultContent: ""
          },
          {
            data: 'gender',
            defaultContent: ""
          },
          {
            data: 'pancard'
          },
          {
            data: 'email',
            defaultContent: ""
          },
          {
            data: 'mobileNumber',
            defaultContent: ""
          },
          {
            data: 'mobileNumberAlternate',
            defaultContent: ""
          },
          {
            data: 'addressLine1',
            defaultContent: ""
          },
          {
            data: 'addressLine2',
            defaultContent: ""
          },
          {
            data: 'landmark',
            defaultContent: ""
          },
          {
            data: 'station',
            defaultContent: ""
          },
          {
            data: 'zipcode',
            defaultContent: ""
          },
          {
            data: 'employmentStatus',
            defaultContent: ""
          },
          {
            data: 'netMonthlyIncome',
            defaultContent: ""
          },
          {
            data: 'yearsEmployed',
            defaultContent: ""
          },
          {
            data: 'company',
            defaultContent: ""
          },
          {
            data: 'companyListed',
            defaultContent: ""
          },
          {
            data: 'companyAddressLine1',
            defaultContent: ""
          },
          {
            data: 'companyAddressLine2',
            defaultContent: ""
          },
          {
            data: 'companyLandmark',
            defaultContent: ""
          },
          {
            data: 'companyStation',
            defaultContent: ""
          },
          {
            data: 'companyCity',
            defaultContent: ""
          },
          {
            data: 'companyZipcode',
            defaultContent: ""
          },
          {
            data: 'extCCBank1',
            defaultContent: ""
          },
          {
            data: 'extCCUsingFrom1',
            defaultContent: ""
          },
          {
            data: 'extCCTotalLimit1',
            defaultContent: ""
          },
          {
            data: 'extCCAvailableLimit1',
            defaultContent: ""
          },
          {
            data: 'extCCBank2',
            defaultContent: ""
          },
          {
            data: 'extCCUsingFrom2',
            defaultContent: ""
          },
          {
            data: 'extCCTotalLimit2',
            defaultContent: ""
          },
          {
            data: 'extCCAvailableLimit2',
            defaultContent: ""
          },
          {
            data: 'updatedBy',
            defaultContent: ""
          },
          {
            data: 'updatedAt',
            defaultContent: ""
          }
        ],
        columnDefs: [
          {
            render: (data) => {
              if (_.includes(data, 'T')) {
                return moment(data).format('DD-MM-YYYY');
              } else if (!data || data === '') {
                return '';
              } else {
                return moment(data, "YYYY-MM-DD").format('DD-MM-YYYY');
              }
            },
            targets: [0, 2, 3, 4, 19]
          },
          {
            render: (data) => {
              if (_.includes(data, 'T')) {
                return moment(data).format('h:mm A');
              } else {
                return '';
              }
            },
            targets: 1
          },
          {
            render: (data) => {
              return this.prettyPrint(data, 'User');
            },
            targets: [6, 7, 8, 50]
          },
          {
            render: (data) => {
              return _.result(_.find(this.getStatuses, (status) => {
                return status.id === data;
              }), 'name');
            },
            targets: 9
          },
          {
            render: (data) => {
              return this.prettyPrint(data, 'Product');
            },
            targets: 13
          },
          {
            render: (data) => {
              if (_.includes(data, 'T')) {
                return moment(data).format('dddd, MMMM Do YYYY h:mm A');
              } else if (!data || data === '') {
                return '';
              } else {
                return moment(data, "YYYY-MM-DD").format('DD-MM-YYYY');
              }
            },
            targets: 51
          },
          {
            visible: false,
            targets: [...((this.jobType === 'User' || this.jobType === 'BackOffice') ? [2, 3] : [6, 7, 8]), 0, 1, 4, 9, 10, 11, 12, 13, 14, 17, 18, 19, 20, 21, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49]
          }
        ],
        buttons: buttons,
        search: {
          regex: true,
          smart: false
        },
        order: [[2, "desc"]]
      });

      this.formsTable.buttons()
          .container()
          .appendTo('#ninetyDaysFormsList_wrapper .col-sm-6:eq(0)');

      this.setupFormModal();
    },
    updateFormsTableData: function () {
      if (this.formsTable) {
        this.formsTable.clear().draw();
        this.formsTable.rows.add(this.forms);
        this.formsTable.draw();
      }
    },
    setMessage: function (type, title, message, timeout = 6000) {
      let content = this.$Amplify.I18n.get(message.message || message);

      if (type === 'alert' || type === 'confirm') {
        return this.$Msg.add(content, {
          type: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      } else {
        this.$Msg.add(content, {
          theme: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      }
    },
    prettyPrint: function (value, type) {
      if (type === 'Date') {
        if (_.includes(value, 'T')) {
          return moment(value).format('MMMM Do YYYY h:mm A');
        } else if (!value || value === '') {
          return '';
        } else {
          return moment(value, "YYYY-MM-DD").format('DD-MM-YYYY');
        }
      } else if (type === 'Currency' && value && value !== '') {
        value = value.toString().replace(/,/g, '');
        let lastThree = value.substring(value.length - 3);
        let otherNumbers = value.substring(0, value.length - 3);
        if (otherNumbers !== '') {
          lastThree = ',' + lastThree;
        }
        return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
      } else if (type === 'Status') {
        return _.result(_.find(this.getStatuses, (status) => {
          return status.id === value;
        }), 'name') || '';
      } else if (type === 'Product') {
        return _.result(_.find(this.getProducts, (product) => {
          return product.id === value;
        }), 'name') || '';
      } else if (type === 'User') {
        let user = _.find(this.getUsers, (user) => {
          return user.username === value;
        });
        if (user) {
          return _.upperCase(user.firstName + ' ' + user.lastName);
        } else {
          return value;
        }
      }
    },
    setupFormModal: function () {
      $('#ninetyDaysFormsList tbody').on('click', 'tr', (e) => {
        this.formDetails = this.formsTable.row(e.currentTarget).data();
        if (this.formDetails) {
          $("#formDetailsModal").modal('show');
        } else {
          this.formDetails = {};
        }
      });
    },
    getTitleUsers: function (titleName) {
      let usersList = _.result(_.find(this.getTitles, (title) => {
        return title.access === titleName;
      }), 'users');
      if (usersList) {
        usersList = _.filter(this.getUsers, (user) => {
          return _.includes(usersList, user.id);
        });
        return usersList;
      } else {
        return [];
      }
    },
    getUserName: function (user) {
      let name = _.capitalize(user.firstName);

      if (user.lastName) {
        name += " " + _.capitalize(user.lastName);
      }
      return name;
    },
    assignForms: async function () {
      if (this.forms.length < 1) {
        this.setMessage('v-notify-error', "Error!", "There are no forms to assign!");
        return;
      }
      if (!this.selectedUser) {
        this.setMessage('v-notify-error', "So Close!", "You must select a user to assign the forms to.");
        return;
      }
      if (!this.assignCount || this.assignCount < 1) {
        this.setMessage('v-notify-error', "Oops!", "Number of forms to assign is set to 0!");
        return;
      }

      if (this.assignCount > this.forms.length) {
        this.assignCount = this.forms.length;
      }
      let formsCopy = _.cloneDeep(this.forms);
      const currentTimeEpoch = moment().format('X');

      for (let i = 0; i < this.assignCount; i++) {
        let currentForm = formsCopy[i];
        if (this.jobType === 'Owner') {
          API.graphql(graphqlOperation(mutations.updateForm, {
            input: {
              id: currentForm.id,
              owner: this.selectedUser.username,
              updatedAtEpoch: currentTimeEpoch,
              expectedVersion: currentForm.version
            }
          }));
          _.remove(formsCopy, (form) => {
            return form.id === currentForm.id;
          });
        } else if (this.jobType === 'Manager') {
          let supervisorUsername = _.result(_.find(this.getUsers, (user) => {
            return user.id === this.selectedUser.supervisor;
          }), 'username', this.getLoggedInUser.username);
          API.graphql(graphqlOperation(mutations.updateForm, {
            input: {
              id: currentForm.id,
              owner: this.selectedUser.username,
              supervisor: supervisorUsername,
              branchManager: this.getLoggedInUser.username,
              updatedAtEpoch: currentTimeEpoch,
              expectedVersion: currentForm.version
            }
          }));
          _.remove(formsCopy, (form) => {
            return form.id === currentForm.id;
          });
        } else if (this.jobType === 'Supervisor') {
          let supervisorUsername = _.result(_.find(this.getUsers, (user) => {
            return user.id === this.getLoggedInUser.supervisor;
          }), 'username', '');
          let input = {
            id: currentForm.id,
            owner: this.selectedUser.username,
            supervisor: this.getLoggedInUser.username,
            updatedAtEpoch: currentTimeEpoch,
            expectedVersion: currentForm.version
          };
          if (supervisorUsername && supervisorUsername !== '') {
            input.branchManager = supervisorUsername;
          }
          API.graphql(graphqlOperation(mutations.updateForm, {
            input: input
          }));
          _.remove(formsCopy, (form) => {
            return form.id === currentForm.id;
          });
        }
      }
      this.forms = formsCopy;
      if (this.assignCount < 2) {
        this.setMessage('v-notify-success', "Nice!", this.assignCount + " form has been assigned to " + this.getUserName(this.selectedUser) + ".");
      } else {
        this.setMessage('v-notify-success', "Nice!", this.assignCount + " forms have been assigned to " + this.getUserName(this.selectedUser) + ".");
      }
    }
  }
}
</script>

<style scoped>

</style>
